import { useMemo } from 'react';
import { getActiveShops, useHistory } from '@frontastic-engbers/lib';

// Returns the currently active shop
// We have a special case for engbers germany which stays active after visiting it and jumping to a product page
export const useCurrentShop = () => {
  const { history } = useHistory();
  const activeShops = getActiveShops();

  const currentShop = useMemo(() => {
    const changingToEngbersIndicators = [
      'account/wishlist',
      'cart',
      'filialfinder',
      '/checkout/login',
      'login',
      'emilio-adani',
      'herrenmode-xxl',
    ];
    let staysEngbersGermany = false;
    const isEngbersGermanyIndicator = ['/engbers-germany/'];

    history.forEach((historyURL) => {
      if (isEngbersGermanyIndicator.find((item) => historyURL.includes(item))) {
        staysEngbersGermany = true;
      } else if (
        staysEngbersGermany &&
        !changingToEngbersIndicators.find((item) => {
          return historyURL.includes(item) || historyURL === '/';
        })
      ) {
        staysEngbersGermany = true;
      } else {
        staysEngbersGermany = false;
      }
    });

    const isRegularEngbersShop = activeShops['engbers'] || !Object.values(activeShops).filter((value) => value).length;

    if (activeShops['emilio-adani']) {
      return 'emilio-adani';
    } else if (activeShops['engbers-germany']) {
      return 'engbers-germany';
    } else if (isRegularEngbersShop && staysEngbersGermany) {
      return 'engbers-germany';
    } else if (activeShops['engbers-xxl']) {
      return 'engbers-xxl';
    } else {
      return 'engbers';
    }
  }, [history.length, activeShops]);

  return currentShop;
};
