import * as React from 'react';
import { SWRConfig } from 'swr';
import { createState } from 'state-pool';
import { fetchApiHub } from '../../lib/fetch-api-hub';
import { FrontasticState, getFrontasticState, GlobalState } from './FrontasticState';
import { UseCart } from './UseCart';
import { UseAccount } from './UseAccount';
import { UseWishlist } from './UseWishlist';
import { UseAdyen } from './UseAdyen';
const initialState: FrontasticState = {
  useCart: {} as UseCart,
  useAccount: {} as UseAccount,
  useWishlist: {} as UseWishlist,
  useAdyen: {} as UseAdyen,
  activeShops: {
    engbers: false,
    'engbers-xxl': false,
    'engbers-germany': false,
    'emilio-adani': false
  },
  projectInfo: {
    name: '',
    projectId: '',
    siteUrl: ''
  },
  navigationTree: null
};
const globalState: GlobalState = {
  isFlyoutCartOpen: false,
  flyoutAlternativeProductsConfig: undefined
};
export const FrontasticContext = React.createContext<FrontasticState>(initialState);
export const GlobalStateContext = createState<GlobalState>(globalState);
interface IFrontasticProvider {
  context: 'pwa' | 'shop';
}
export const FrontasticProvider: React.FC<IFrontasticProvider> = ({
  children,
  context
}) => {
  const state: FrontasticState = getFrontasticState(context);
  return <SWRConfig value={{
    fetcher: fetchApiHub
  }} data-sentry-element="SWRConfig" data-sentry-component="FrontasticProvider" data-sentry-source-file="index.tsx">
      <FrontasticContext.Provider value={state} data-sentry-element="unknown" data-sentry-source-file="index.tsx">{children}</FrontasticContext.Provider>
    </SWRConfig>;
};
const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};
export const useCart = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useCart;
};
export const useAccount = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useAccount;
};
export const useWishlist = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useWishlist;
};
export const useAdyen = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useAdyen;
};
export const setActiveShops = activeShops => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  context.activeShops = activeShops;
};
export const getActiveShops = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.activeShops;
};
export const setProjectInfo = projectInfo => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  context.projectInfo = projectInfo;
};
export const getProjectInfo = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.projectInfo;
};
export const setNavigationTree = navigationTree => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  context.navigationTree = navigationTree;
};
export const getNavigationTree = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.navigationTree;
};